import React, { useContext } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { IconSvg } from '../IconSvg';
import { useHttp } from 'src/hooks';
import { setToLocalStorage } from '../../../../utils';
import { AuthContext } from '../../../../contexts';
import { useNavigate } from 'react-router-dom';
import { LoadingSpinner } from '../loading-spinner/LoadingSpinner';

interface Props {
  label: React.ReactNode;
  type: React.ReactNode;
  planId?: string;
  currencyId?: string;
}
const GoogleLoginButton: React.FC<Props> = ({ label, type, planId, currencyId }) => {
  const { sendRequest: apiRequestHandler, isLoading: isLoading, error } = useHttp();
  const { accessToken, setAccessToken } = useContext(AuthContext);
  const navigate = useNavigate();

  const login = useGoogleLogin({
    onSuccess: response => {
      if (type === 'signup') {
        apiRequestHandler(
          {
            method: 'POST',
            url: `auth/google/signup`,
            payload: {
              accessToken: response.access_token,
              priceId: planId,
              currencyId: currencyId,
            },
          },
          (data: any) => {
            setToLocalStorage(`::@@xploriusJwtAuthspajs@@::`, data);
            setAccessToken(data.accessToken);
            navigate('/sign-up?step=3');
          },
        );
      }

      if (type === 'login') {
        apiRequestHandler(
          {
            method: 'POST',
            url: `auth/google/login`,
            payload: { accessToken: response.access_token },
          },
          (data: any) => {
            setToLocalStorage(`::@@xploriusJwtAuthspajs@@::`, data);
            setAccessToken(data.accessToken);
            // navigate('/booking');
            navigate('/sign-up?step=3');
          },
        );
      }

      // Handle successful login response
    },
    onError: () => {
      console.error('Login Failed');
      // Handle login error
    },
  });

  return (
    <div
      className="border rounded-[5px] border-[#4A4A4A] py-2 flex justify-center items-center gap-4 bg-[#4A4A4A] cursor-pointer"
      onClick={() => login()}
    >
      <IconSvg icon="google" />
      <p className="text-[#EFE8DF] text-base font-medium font-inter leading-[19.2px]">{label}</p>
      {isLoading && <LoadingSpinner />}
    </div>
  );
};

export default GoogleLoginButton;
