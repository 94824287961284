import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { Button, Footer, IconSvg } from 'src/components/common';
import Styles from '../../../components/booking/booking.module.scss';
import { AuthContext } from 'src/contexts';
import { GuestsMenuCard } from 'src/components/booking/menu-cards/GuestesMenuCard';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import usePlacesAutocompleteService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { useHttp, useNotification } from 'src/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icons } from 'src/types/icons';
import { MenuCard } from 'src/components/common/ui/header/MenuCard';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface Options {
  name: string;
  value: string;
  path: string;
  icon: Icons;
  active: boolean;
  activeIcon: Icons;
}

interface Room {
  adults: number;
  children: { age: string; isValid: boolean }[];
}
type DateRange = [Date, Date];

type OutputType = {
  numOfRoom: number;
  numOfAdults: number;
  numOfChildren: number;
  childAges: number[];
};

interface MembersBookedHotel {
  img: Icons;
  location: string;
}

interface PartnersData {
  img: Icons;
}

const faqs = [
  {
    id: '1',
    question: 'I found a better rate elsewhere, what should I do?',
    answer: `Be sure to reach out to our client service team. If you find a lower rate elsewhere compared to what's listed on our platform, while we can't guarantee it, we'll certainly do our best to match or even beat that rate for you!`,
  },
  {
    id: '2',
    question:
      'I am looking to book a specific hotel, but I can’t find it on the platform. Can you help me out?',
    answer: `We always go an extra mile for our members! Get in touch with our client service team and we’ll look into all available opportunities.`,
  },
  {
    id: '3',
    question: 'What payment options are available on the platform?',
    answer: `Our platform supports all major currencies. You can also pay in cryptocurrency.`,
  },
  {
    id: '4',
    question: 'Is there a limit on how many hotel bookings I can make on XPLORIUS?',
    answer: `At the moment, we don’t have a cap on how many bookings you’re allowed to make throughout the year. Have fun with it!`,
  },
  {
    id: '5',
    question: 'What are XPLORIUS membership options and cancellation policy?',
    answer: `We offer biannual and annual memberships with the flexibility to cancel anytime before the renewal date, no strings attached. For a smooth cancellation, we advise initiating the process at least 7 days prior. Please note that cancellations apply to upcoming subscriptions only. Memberships auto-renew unless cancelled. For more details, please read our Terms of sale & Terms of use.`,
  },
  // {
  //   id: '6',
  //   question: "Won't if be cheaper for me to book a hotel directly on the hotel's website?",
  //   answer: `This is a very common misconception that booking directly on the hotel’s website is always “the best rate guarantee”. This is only applicable to publicly available hotel rates, but you can do so much better than that with our hidden deals!`,
  // },
];
const formatFullDate = (date: Date) => {
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });
  const weekday = date.toLocaleString('default', { weekday: 'short' });

  return `${weekday} ${day} ${month}`;
};
export function Booking() {
  const notify = useNotification();
  const { pathname } = useLocation();
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  const navigate = useNavigate();
  const [selectFaq, setSelectFaq] = useState<string[]>(['1']);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [sliderRef, setSliderRef] = useState<Slider | null>(null);

  const { logout, accessToken, profile, userAuth0, customLogoutHandler, appConfig } =
    useContext(AuthContext);
  const [dateRange, setDateRange] = useState<DateRange>([today, tomorrow]);
  const [coordinates, setCoordinates] = useState<{ lat: number | null; lng: number | null }>({
    lat: null,
    lng: null,
  });
  const { sendRequest: apiRequestHandler, error, isLoading } = useHttp();

  const [roomsData, setRoomsData] = useState<Room[]>([{ adults: 2, children: [] }]);

  const onChangeMenuMember = (value: string, name: string, path: string) => {
    console.log({ value });
    if (value == 'Sign out') {
      customLogoutHandler();
      return;
    }

    if (value == 'My trips') {
      window.open(path, '_blank');
      return;
    }
    navigate(path);
  };

  const submitGuestHandler = (payload: Room[]) => {
    console.log({ payload });
    setRoomsData(payload);
  };

  const totalRooms = roomsData.length;
  const totalAdults = roomsData.reduce((sum, room) => sum + room.adults, 0);
  const totalChildren = roomsData.reduce((sum, room) => sum + room.children.length, 0);

  const [showSuggestions, setShowSuggestions] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setShowSuggestions(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const [inputValue, setInputValue] = useState('');
  const [selectedPlace, setSelectedPlace] = useState<any>(null);

  const { placesService, placePredictions, getPlacePredictions } = usePlacesAutocompleteService({
    apiKey: 'AIzaSyBh3w6t7z70lGIg9CJ403P_HSqWZiLrDrM',
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedPlace(null);
    const value = e.target.value;
    setInputValue(value);
    if (value) {
      setShowSuggestions(true);

      getPlacePredictions({ input: value });
    }
  };

  const handlePlaceSelect = (placeId: string) => {
    if (placesService) {
      placesService.getDetails({ placeId }, (place: any, status: any) => {
        setCoordinates({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        });

        // if (status === google.maps.places.PlacesServiceStatus.OK) {
        setSelectedPlace(place);
        setInputValue(place.name || '');
        setShowSuggestions(false);
        // }
      });
    }
  };
  const handleInputFocus = () => {
    if (inputValue && placePredictions.length > 0) {
      setShowSuggestions(true);
    }
    setIsInputFocused(true);
  };

  function transformData(inputData: Room[]): OutputType[] {
    return inputData.map((entry, index) => {
      const numOfRoom = index + 1;
      const numOfAdults = entry.adults;
      const numOfChildren = entry.children.length;
      const childAges = entry.children.map(child => {
        const ageMatch = child.age.match(/\d+/);
        return ageMatch ? parseInt(ageMatch[0], 10) : 0;
      });

      return {
        numOfRoom,
        numOfAdults,
        numOfChildren,
        childAges,
      };
    });
  }

  function formatDateRange(dates: Date[]) {
    const minDate = dates[0];
    const maxDate = dates[1];

    // Format start date
    const startDate = formatDate(minDate);

    // Format end date
    const endDate = formatDate(maxDate);

    return { start: startDate, end: endDate };
  }

  function formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = padZero(date.getMonth() + 1); // Months are zero-indexed
    const day = padZero(date.getDate());
    return `${year}-${month}-${day}`;
  }

  function padZero(num: number): string {
    return num.toString().padStart(2, '0');
  }

  const handleDateRangeChange = (value: [Date, Date] | null) => {
    if (value) {
      setDateRange(value);
    } else {
      setDateRange([new Date(), new Date()]);
    }
  };

  const searchHandler = () => {
    console.log('ENVVVV', process.env.REACT_APP_GOOGLE_API_KEY);
    if (!selectedPlace) {
      notify('Pleas select location ', 'warning');
      return;
    }
    const params = {
      type: 'hotel',
      version: 'one',
      code: `${
        selectedPlace?.address_components?.find((component: any) =>
          component.types.includes('country'),
        )?.short_name
      }`,
      locationId: 'googleLocation',
      lat: coordinates.lat,
      long: coordinates.lng,
      nationality: `{"name": "${
        selectedPlace?.address_components?.find((component: any) =>
          component.types.includes('country'),
        )?.long_name
      }","alpha2Code":"${
        selectedPlace?.address_components?.find((component: any) =>
          component.types.includes('country'),
        )?.short_name
      }"}`,
      occupancies: JSON.stringify(transformData(roomsData)),
      paging: '{"pageNo":1,"pageSize":50,"orderBy":"rating desc, price desc"}',
      premise: inputValue,
      radius: 50,
      searchString: `${selectedPlace?.formatted_address}`,
      stayPeriod: `${JSON.stringify(formatDateRange(dateRange))}`,
      distance: 15,
      source: 'xplorious-dev',
      chlocality: selectedPlace?.address_components?.find((component: any) =>
        component.types.includes('locality'),
      )?.long_name,
    };
    apiRequestHandler(
      {
        url: `auth/generate-token`,
        accessToken,
        method: 'POST',
      },
      (response: any) => {
        const baseUrl = 'https://booking.xplorius.com';
        const token = response?.accessToken;

        let paramString = '';
        for (const key in params) {
          if (Object.prototype.hasOwnProperty.call(params, key)) {
            paramString =
              paramString + encodeURIComponent(`${key}=${params[key as keyof typeof params]}&`);
          }
        }

        const url = `${baseUrl}/sso?token=${token}&navigateTo=%2Fbooknow%2Fhotels%2Fsearch%3F${paramString}`;
        // console.log('url', url);
        window.location.href = url;
        // window.open(url, '_blank');
      },
    );
  };
  const faqSectionRef = useRef<HTMLDivElement>(null);
  const helpSectionRef = useRef<HTMLDivElement>(null);
  const scrollToHelp = () => {
    if (helpSectionRef.current) {
      window.scrollTo({
        top: helpSectionRef.current.offsetTop,
        behavior: 'smooth',
      });
    }
  };
  const scrollToFAQ = () => {
    if (faqSectionRef.current) {
      window.scrollTo({
        top: faqSectionRef.current.offsetTop,
        behavior: 'smooth',
      });
    }
  };
  const [myTripUrl, setMyTripUrl] = useState<string>('');

  const getTokenHandler = () => {
    apiRequestHandler(
      {
        url: `auth/generate-token`,
        accessToken,
        method: 'POST',
      },
      (response: any) => {
        const baseUrl = 'https://booking.xplorius.com';
        const token = encodeURIComponent(response?.accessToken);
        const navigateTo = encodeURIComponent('/mytrip/mytrips');
        const url = `${baseUrl}/sso?token=${token}&navigateTo=${navigateTo}`;
        setMyTripUrl(url);
      },
    );
  };

  useEffect(() => {
    accessToken && getTokenHandler();
  }, [accessToken]);

  const optionsData: Options[] = [
    {
      name: 'My profile',
      value: 'My profile',
      active: pathname == '/profile',
      icon: 'userBlack',
      activeIcon: 'userCopperRed',
      path: '/profile',
    },
    {
      name: 'Book now',
      value: 'Book now',
      active: pathname.includes('booking'),
      icon: 'bookNowBlack',
      activeIcon: 'bookNowCopperRed',
      path: '/booking',
    },
    {
      name: 'My trips',
      value: 'My trips',
      active: pathname.includes('trips'),
      icon: 'airplaneBlack',
      activeIcon: 'airplaneBlack',
      path: `${myTripUrl}`,
    },
    {
      name: 'Billing',
      value: 'Billing',
      active: pathname.includes('billing'),
      icon: 'creditCardBlack',
      activeIcon: 'creditCardCopperRed',
      path: '/billing',
    },
    {
      name: 'Help',
      value: 'Help',
      active: pathname.includes('help'),
      icon: 'helpBlack',
      activeIcon: 'helpCopperRed',
      path: '/help',
    },
    {
      name: 'Sign out',
      value: 'Sign out',
      active: false,
      icon: 'signOutBlack',
      activeIcon: 'signOutBlack',
      path: '',
    },
  ];
  if (appConfig?.isAdmin) {
    const newOption: Options = {
      name: 'Coupons',
      value: 'Coupons',
      active: pathname.includes('coupon'),
      icon: 'helpBlack',
      activeIcon: 'helpCopperRed',
      path: '/coupons',
    };
    optionsData.splice(optionsData.length - 1, 0, newOption);
  }
  useEffect(() => {
    if (userAuth0?.sub && !profile?.account?.plan) {
      return navigate('/profile');
    }
  }, [userAuth0?.sub, profile?.account?.plan, location.pathname]);

  const formattedDateRange = `${formatFullDate(dateRange[0])} - ${formatFullDate(dateRange[1])}`;

  const navigateToWhatsapp = () => {
    window.open('https://wa.me/message/6GFBB37QHD6UN1', '_blank');
  };

  const settings = {
    centerMode: true,
    centerPadding: '0',
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    // centerHeight: '460px',
    // centerWidth: '362px',
    slidesToScroll: 1,
    beforeChange: (oldIndex: number, newIndex: number) => setCurrentIndex(newIndex),
    prevArrow: <IconSvg icon="leftShortArrowGreen" className="" />,
    nextArrow: <IconSvg icon="rightShortArrowGreen" className="" />,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const settingsMobileScreen = {
    centerMode: true,
    centerPadding: '0',
    dots: false,
    infinite: true,
    speed: 1000,
    arrows: false,
    centerHeight: '227px',
    centerWidth: '300px',
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    prevArrow: (
      <div className="arrow" onClick={() => sliderRef && sliderRef.slickPrev()}>
        <IconSvg icon="leftLongArrowGreen" className="w-[38px] h-[16px]" />
      </div>
    ),
    nextArrow: (
      <div className="arrow " onClick={() => sliderRef && sliderRef.slickNext()}>
        <IconSvg icon="rightLongArrowGreen" className="w-[38px] h-[16px]" />
      </div>
    ),
  };

  const sliderData: MembersBookedHotel[] = [
    {
      img: 'vip1',
      location: 'Auberge du soleil Napa Valley, CA',
    },
    {
      img: 'vip2',
      location: 'The Selman, Marrakech',
    },
    {
      img: 'vip3',
      location: 'The Ritz Carlton Yacht collection',
    },
    {
      img: 'vip4',
      location: 'Splendido, A Belmond Hotel, Portofino',
    },
    {
      img: 'vip5',
      location: 'One and Only Aesthesis, Athens',
    },
    {
      img: 'vip6',
      location: 'Las Ventanas al Paraiso, a Rosewood Resort',
    },
    {
      img: 'vip7',
      location: 'Castello di Reschio',
    },
    {
      img: 'vip8',
      location: 'Belmond train',
    },
  ];

  const partnersData: PartnersData[] = [
    
  
    {
      img: 'partner2',
    },
    {
      img: 'partner3',
    },
    {
      img: 'partner4',
    },
    {
      img: 'partner5',
    },
    {
      img: 'partner6',
    },
    {
      img: 'partner7',
    },
    {
      img: 'partner8',
    },
    {
      img: 'partner9',
    },
    {
      img: 'partner10',
    },
    {
      img: 'partner1',
    },
    
  ];

  const partnerSettings = {
    // centerMode: true,
    // centerPadding: '0',
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    centerHeight: '174px',
    centerWidth: '199px',
    slidesToScroll: 1,
    // beforeChange: (oldIndex: number, newIndex: number) => setCurrentIndex(newIndex),
    prevArrow: <IconSvg icon="leftLongArrowGreen" className="w-[44px] h-[25px]" />,
    nextArrow: <IconSvg icon="rightLongArrowGreen" className="w-[44px] h-[25px]" />,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const partnerSettingsMobileScreen = {
    // centerMode: true,
    // centerPadding: '0',
    dots: false,
    infinite: true,
    speed: 1000,
    arrows: false,
    centerHeight: '227px',
    centerWidth: '300px',
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    prevArrow: (
      <div className="arrow" onClick={() => sliderRef && sliderRef.slickPrev()}>
        <IconSvg icon="leftLongArrowGreen" className="w-[38px] h-[16px]" />
      </div>
    ),
    nextArrow: (
      <div className="arrow " onClick={() => sliderRef && sliderRef.slickNext()}>
        <IconSvg icon="rightLongArrowGreen" className="w-[38px] h-[16px]" />
      </div>
    ),
  };

  const getSlideClass = (index: number) => {
    if (index === currentIndex) return 'slick-center'; // Center element
    if (index === currentIndex - 1 || (currentIndex === 0 && index === sliderData.length - 1)) {
      return 'slick-left'; // Left element
    }
    if (index === currentIndex + 1 || (currentIndex === sliderData.length - 1 && index === 0)) {
      return 'slick-right'; // Right element
    }
    return '';
  };

  const handleHereClick = () => {
    window.open('https://wa.me/message/6GFBB37QHD6UN1', '_blank');
  };

  return (
    <>
      <div className="flex flex-col items-center w-full ">
        <div className="w-full h-full">
          <p className="bg-[#C56B48] uppercase font-inter font-medium text-sm lg:leading-4 leading-5 py-3 text-[#F5F5F5] text-center">
            Please scroll down & check out our new exciting VIP services!
          </p>
          <div
            className="bg-[#3E7A63] lg:h-[800px] lg:pb-0 pb-4"
            style={{
              backgroundImage: `url("/assets/images/curveRoundedLineCream.svg")`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
          >
            <div className="lg:px-10 px-2.5">
              <div className="lg:py-6 py-4 lg:border-b lg:border-[#EFE8DF]">
                <div className="flex items-center justify-between">
                  <div className=" flex items-center justify-center rounded">
                    <IconSvg
                      icon="xploriusLogoCream"
                      className="lg:w-[149.21px] lg:h-[35.35px] sm:w-[92px] sm:h-[22px] w-[109px] h-[26px] cursor-pointer"
                      onClick={() => navigate('/')}
                    />
                  </div>
                  <div className="lg:flex items-baseline gap-8 hidden">
                    <a onClick={scrollToFAQ}>
                      <p className="text-[#EFE8DF] cursor-pointer font-light font-inter text-base leading-[19.2px]">
                        FAQ
                      </p>
                    </a>
                    <a onClick={scrollToHelp}>
                      <p className="text-[#EFE8DF] cursor-pointer font-light font-inter text-base leading-[19.2px]">
                        HELP
                      </p>
                    </a>
                  </div>

                  <div className="lg:block hidden">
                    {accessToken && profile?.firstName && (
                      <MenuCard
                        onChange={(value: string, name: string, path: string) =>
                          onChangeMenuMember(value, name, path)
                        }
                        className="w-[137px]"
                        options={optionsData}
                        placeholder={`${
                          profile?.firstName
                            ? profile?.firstName?.includes('@')
                              ? profile?.firstName?.split('@')[0]?.length > 5
                                ? profile?.firstName?.split('@')[0]?.slice(0, 5) + '...'
                                : profile?.firstName?.split('@')[0]
                              : profile?.firstName.length > 5
                              ? profile?.firstName.slice(0, 5) + '...'
                              : profile?.firstName
                            : profile?.email?.split('@')[0]?.length > 5
                            ? profile?.email?.split('@')[0]?.slice(0, 5) + '...'
                            : profile?.email?.split('@')[0]
                        }`}
                      />
                    )}
                  </div>
                  <div className="lg:hidden block">
                    <MenuCard
                      onChange={(value: string, name: string, path: string) =>
                        onChangeMenuMember(value, name, path)
                      }
                      className="w-[137px]"
                      options={optionsData}
                      // placeholder={`${profile?.firstName}`}
                      placeHolderIcon={
                        <div className="h-[25px] w-[25px] bg-[#C56B48] rounded-full">
                          <IconSvg icon="userCream" className="h-full w-full" />
                        </div>
                      }
                      icon={true}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className="lg:px-10 px-2.5">
                <div className="lg:mt-9">
                  <div className="flex items-center justify-between py-6 ">
                    <div className="w-full sm:block hidden">
                      <div className="flex lg:gap-10 gap-4">
                        <IconSvg
                          icon="snowMountainsBooking"
                          className="h-[101px] w-auto lg:block hidden"
                        />
                        <IconSvg
                          icon="snowMountainsBookingSmall"
                          className="h-11  w-[95px] lg:hidden block"
                        />
                        <div>
                          <h1 className="lg:text-2xl text-base font-light text-[#EFE8DF] font-inter uppercase lg:leading-[28.8px] leading-[19.2px]">
                            Stop overpaying for hotels.
                          </h1>
                          <h1 className="lg:text-6xl text-3xl lg:font-light sm:font-medium text-[#EFE8DF] uppercase lg:pt-[18px] font-inter lg:!leading-[96px] leading-9">
                            Book your favorite hotels at
                          </h1>
                        </div>
                        {/* /ok  test*/}
                      </div>
                      <h1 className="lg:text-6xl text-3xl lg:font-light sm:font-medium text-[#EFE8DF] font-inter lg:!leading-[96px] leading-9">
                        <span className=" font-bold text-[#EFE8DF] px-2 border-[2px] border-[#EFE8DF] rounded mr-1 font-inter">
                          UP TO 70% OFF
                        </span>
                        PUBLIC RATES.
                      </h1>
                    </div>

                    {/* This top section for mobile start */}
                    <div className="w-full sm:hidden block">
                      <h1 className="lg:text-2xl text-base font-medium text-[#EFE8DF] font-inter uppercase lg:leading-[28.8px] leading-[19.2px]">
                        Stop overpaying for hotels.
                      </h1>
                      <p className="align-top mt-[14px]">
                        <IconSvg
                          icon="snowMountainsBooking"
                          className="h-[33px] w-[98.04px] inline-block pb-[8px]"
                        />
                        <span className="text-3xl font-medium text-[#EFE8DF] uppercase font-inter leading-9 ">
                          Book your favorite hotels at
                          <br />
                          <span className=" font-bold text-[#EFE8DF] px-2 border-[2px] border-[#EFE8DF] rounded mr-1 font-inter">
                            UP TO 70% OFF
                          </span>
                          <br />
                          PUBLIC RATES.
                        </span>
                        {/* /ok  test*/}
                      </p>
                      <h1 className="lg:text-6xl text-3xl font-light text-[#EFE8DF] font-inter lg:!leading-[96px] leading-9"></h1>
                    </div>
                    {/* This top section for mobile end */}
                  </div>
                  <div
                    className="flex lg:flex-row flex-col justify-between lg:h-[84px] bg-[#E2BA13] p-3 gap-[15px] lg:mt-10 sm:mt-[34px] mt-6 rounded-[5px]"
                    style={{ boxShadow: '0px 4px 43.7px 0px #00000040' }}
                  >
                    <div className="w-full">
                      <div className="autocomplete-container">
                        <div className="autocomplete-wrapper" ref={wrapperRef}>
                          <span className="custom-icon">
                            <IconSvg
                              icon="locationMarker"
                              className="lg:h-auto lg:w-auto h-[22.75px] w-[15.75px]"
                            />
                          </span>

                          <input
                            type="text"
                            placeholder="Where are you going"
                            value={inputValue}
                            onChange={handleInputChange}
                            className="autocomplete-input"
                            ref={inputRef}
                            onFocus={handleInputFocus}
                            // onBlur={handleInputBlur}
                          />
                          {showSuggestions && isInputFocused && placePredictions.length > 0 && (
                            <ul className="autocomplete-suggestions mt-2">
                              {placePredictions.map(prediction => (
                                <li
                                  key={prediction.place_id}
                                  className="autocomplete-suggestion"
                                  onClick={() => handlePlaceSelect(prediction.place_id)}
                                >
                                  <div className="flex items-center gap-3 px-3">
                                    <IconSvg icon="dotLocationMarkerGreen" />
                                    <div>
                                      <p className="suggestion-main">
                                        {prediction.structured_formatting.main_text}
                                      </p>
                                      <p className="suggestion-secondary">
                                        {prediction.structured_formatting.secondary_text}
                                      </p>
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="w-full">
                      <div
                        className={`${Styles['date-range-picker-booking']} flex items-center bg-[#EFE8DF] rounded-[5px] pl-2`}
                      >
                        <IconSvg
                          icon="calendar"
                          className="lg:h-auto lg:w-auto h-[21px] w-[21px]"
                        />
                        <DateRangePicker
                          value={dateRange}
                          onChange={handleDateRangeChange}
                          format="MM/dd/yyyy"
                          character="-"
                          className="w-full lg:h-[60px] h-10 bg-[#EFE8DF] rounded-[5px]"
                          color="#EFE8DF"
                          showHeader={false}
                          caretAs={null}
                          aria-label="text-xl"
                          menuStyle={{ backgroundColor: '#EFE8DF' }}
                          menuClassName="booking-menu"
                          renderValue={() => formattedDateRange}
                        />
                      </div>
                    </div>
                    <div className="w-full ">
                      <GuestsMenuCard
                        onChange={(value: string, name: string) => console.log('menu')}
                        className="w-full"
                        placeholder={`${totalAdults} adult | ${totalChildren} children | ${totalRooms} room`}
                        onApplyHandler={submitGuestHandler}
                      />
                    </div>
                    <div
                      className={`lg:w-[216px] w-full   bg-[#4C4C4C] flex items-center rounded-[5px] ${'cursor-pointer'} `}
                      onClick={() => searchHandler()}
                    >
                      <div className="lg:w-[216px] text-center w-full lg:py-0 sm:py-[14px] py-2 flex justify-center items-center bg-[#4C4C4C] rounded-[5px]">
                        <p className="uppercase font-medium font-inter  text-xl text-[#EFE8DF]">
                          search
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* For Desktop Screen */}
          <div
            className="lg:grid hidden grid-cols-12 pt-[120px] w-full bg-[#EFE8DF] gap-10"
            ref={helpSectionRef}
          >
            <div className="col-span-4 flex justify-end">
              <IconSvg icon="oDotWtihBackgroundWallTree" />
            </div>
            <div className="col-span-8">
              <p className="font-medium text-[40px] leading-[48.36px] uppercase font-bigCaslon text-[#0F0F0F]">
                Struggling to find a hotel or <br />
                found a better rate elsewhere?
              </p>
              <p className="font-light text-lg leading-[21.6px] mt-5 text-[#4A4A4A] font-inter">
                Don&apos;t worry, just contact our team. We&apos;re committed to going the extra
                mile to secure the
                <br /> best rates at any property you desire.
              </p>
              <Button
                size="medium"
                color="primary"
                label={
                  <p className="uppercase font-medium text-xl leading-6 text-[#EFE8DF] font-inter">
                    INQUIRE
                  </p>
                }
                className="mt-6 px-[60px] h-[60px]"
                onClick={() => navigateToWhatsapp()}
              />
            </div>
          </div>
          {/* For mobile screen */}
          <div
            className="lg:hidden flex flex-col sm:py-[50px] py-10 w-full bg-[#EFE8DF] lg:px-0 px-6"
            id="help"
          >
            <div className="flex justify-center">
              <IconSvg icon="oDotWtihBackgroundWallTree" className="w-[156px] h-[156px]" />
            </div>
            <div className="lg:mt-0 mt-5">
              <p className="font-medium lg:text-[40px] sm:text-[32px] text-2xl text-center lg:leading-[48.36px] sm:leading-[38.69px] leading-[29.02px] uppercase font-bigCaslon text-[#0F0F0F]">
                Struggling to find a hotel or <br className="lg:block hidden" />
                found a better rate elsewhere?
              </p>
              <p className="font-normal lg:text-lg text-sm text-center lg:leading-[21.6px] leading-[19.2px] lg:mt-3 sm:mt-5 mt-3  text-[#4A4A4A] font-inter">
                Don&apos;t worry, just contact our team. We&apos;re committed to going the extra
                mile to secure the
                <br className="lg:block hidden" /> best rates at any property you desire.
              </p>
              <div className="flex justify-center">
                <Button
                  size="medium"
                  color="primary"
                  label={
                    <p className="uppercase font-medium lg:text-xl text-base lg:leading-6 leading-[19.2px] text-[#EFE8DF] font-inter">
                      INQUIRE
                    </p>
                  }
                  className="lg:mt-6 sm:mt-[30px] mt-10 text-center sm:px-10 px-0 lg:w-[153px] sm:w-[198px] w-[153px]"
                  onClick={() => navigateToWhatsapp()}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col bg-[#EFE8DF] lg:px-10  px-2.5  lg:pt-[130px] sm:pt-[60px]">
            <div className="lg:flex hidden items-start space-x-3">
              <p className="font-inter uppercase font-medium text-[#0F0F0F] lg:text-[40px] text-[30px] lg:leading-[47.2px] leading-[35.4px]">
                FOR THOSE SEEKING TO BE TREATED LIKE A VIP
              </p>
              <p className="bg-[#C56B48] uppercase rounded-[5px] text-[#EFE8DF] font-inter font-medium text-sm leading-4 py-1.5 px-2">
                New
              </p>
            </div>

            <p className="lg:hidden block items-center font-inter uppercase font-medium text-[#0F0F0F] lg:text-[40px] text-[30px] lg:leading-[47.2px] leading-[35.4px]">
              FOR THOSE SEEKING TO BE TREATED LIKE A VIP{' '}
              <span className="bg-[#C56B48] uppercase rounded-[5px] text-[#EFE8DF] font-inter font-medium text-sm leading-4 py-1.5 px-2">
                New
              </span>
            </p>

            <p className="font-inter font-normal lg:text-lg text-sm text-[#4A4A4A] py-[35px] lg:leading-8 leading-[25.2px]">
              At XPLORIUS, we grant you access to exclusive perks at the world&apos;s most luxurious
              hotels. While these perks <br className="lg:block hidden" /> often don&apos;t come
              with significantly discounted rates, they offer exceptional benefits that enhance your
              stay, <br className="sm:block hidden" />
              ensuring you are treated like a true vip.
            </p>

            <div className="flex lg:flex-row flex-col lg:space-x-8 space-x-0">
              <>
                <div className="lg:w-1/2 w-full">
                  <div className="slick-slider-container-booking-vip lg:pt-[0px] sm:pt-5 lg:pr-7 pr-2.5 lg:pl-0 pl-2.5">
                    <Slider {...settings}>
                      {sliderData.map((item, i) => (
                        <>
                          <Fragment key={i}>
                            <div className={`slider-item h-full ${getSlideClass(i)}`}>
                              <IconSvg icon={item.img} />
                            </div>
                          </Fragment>
                        </>
                      ))}
                    </Slider>
                  </div>

                  <div className="flex justify-center items-center w-full mt-[7px]">
                    <p className="font-inter lg:text-lg text-xs lg:font-normal font-medium text-[#4A4A4A] pr-12 text-center pt-[20px] !mt-0">
                      {sliderData[currentIndex]?.location}
                    </p>
                  </div>
                </div>
              </>
              <div className="flex flex-col lg:mt-0 mt-[32px]">
                <p className="font-inter uppercase lg:text-2xl text-[22px] lg:font-semibold font-medium lg:leading-7 leading-6 text-[#0F0F0F]">
                  Perks vary by property, but typically include:
                </p>

                <div className="pt-8">
                  <div className="flex items-start space-x-4 mb-5">
                    <IconSvg icon="oDotSmallOrange" />
                    <p className="font-inter lg:text-lg text-sm font-normal text-[#4A4A4A]">
                      Preferred rates
                    </p>
                  </div>

                  <div className="flex items-start space-x-4 mb-5">
                    <IconSvg icon="oDotSmallOrange" />
                    <p className="font-inter lg:text-lg text-sm font-normal text-[#4A4A4A]">
                      Complimentary daily breakfast for two
                    </p>
                  </div>

                  <div className="flex items-start space-x-4 mb-5">
                    <IconSvg icon="oDotSmallOrange" />
                    <p className="font-inter lg:text-lg text-sm font-normal text-[#4A4A4A]">
                      $100 property credit (F&B or SPA)
                    </p>
                  </div>

                  <div className="flex items-start space-x-4 mb-5">
                    <IconSvg icon="oDotSmallOrange" />
                    <p className="font-inter lg:text-lg text-sm font-normal text-[#4A4A4A]">
                      Room upgrade (subject to availability)
                    </p>
                  </div>

                  <div className="flex items-start space-x-4 mb-5">
                    <IconSvg icon="oDotSmallOrange" />
                    <p className="font-inter lg:text-lg text-sm font-normal text-[#4A4A4A]">
                      Early check-in / late check-out (subject to availability)
                    </p>
                  </div>

                  <div className="flex items-start space-x-4 mb-8">
                    <IconSvg icon="oDotSmallOrange" />
                    <p className="font-inter lg:text-lg text-sm font-normal text-[#4A4A4A]">
                      Welcome amenities
                    </p>
                  </div>

                  <p className="font-inter lg:text-lg text-sm font-normal text-[#4A4A4A]">
                    Please note that VIP bookings with perks can only be processed internally and
                    are not <br className="lg:block hidden" /> available through the platform.
                  </p>

                  <div className="md:flex items-center hidden space-x-1 mt-8">
                    <div className="font-inter lg:text-lg text-sm font-normal text-[#4A4A4A]">
                      To inquire, please contact our trip managers directly
                    </div>
                    <div
                      onClick={() => handleHereClick()}
                      className="font-inter sm:text-lg text-sm text-[#C56B48] sm:font-extrabold font-medium underline underline-offset-2 cursor-pointer"
                    >
                      here.
                    </div>
                  </div>
                  <p className="md:hidden block font-inter lg:text-lg text-sm font-normal text-[#4A4A4A] mt-8">
                    To inquire, please contact our trip managers directly{' '}
                    <span
                      onClick={() => handleHereClick()}
                      className="font-inter sm:text-lg text-sm text-[#C56B48] sm:font-extrabold font-medium underline underline-offset-2 cursor-pointer"
                    >
                      here.
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col bg-[#EFE8DF] lg:px-10 px-2.5 pt-14">
            <p className="font-inter text-[#0F0F0F] font-medium lg:text-[40px] sm:text-[30px] text-2xl lg:leading-[47.2px] sm:leading-[35.4px] leading-[28.32px] sm:mb-[50px]">
              YOU HAVE EXCLUSIVE ACCESS TO
            </p>

            <>
              <div className="w-full sm:block hidden">
                <div className="slick-slider-container-booking-exclusive">
                  <Slider {...partnerSettings}>
                    {partnersData.map((item, i) => (
                      <>
                        <div key={i} className="flex justify-center items-center">
                          <div className="border-l-[0.5px] h-[116px] border-[#4A4A4A]"></div>
                          <div className="slider-item lg:px-[70px] px-[54px]">
                            <IconSvg icon={item.img} />
                          </div>
                        </div>
                      </>
                    ))}
                  </Slider>
                </div>
              </div>

              <div className="w-full sm:hidden block mt-[29px]">
                <div className="slick-slider-container-booking-exclusive-mobile lg:pt-10 sm:pt-5 relative">
                  <Slider {...partnerSettingsMobileScreen} ref={slider => setSliderRef(slider)}>
                    {partnersData.map((item, i) => (
                      <div key={i}>
                        <div className="w-full flex justify-center items-center ">
                          <div className="border-l-[0.5px] h-[73px] border-[#4A4A4A] mr-[23px]"></div>
                          <IconSvg
                            icon={item.img}
                            className="rounded-[5px] !w-[164px] !h-[110px] object-contain"
                          />
                          <div className="border-l-[0.5px] h-[73px] border-[#4A4A4A] ml-[23px]"></div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                  <div className="slick-arrows flex items-center justify-between absolute top-[50%] w-full">
                    {partnerSettingsMobileScreen.prevArrow}
                    {partnerSettingsMobileScreen.nextArrow}
                  </div>
                </div>
              </div>
            </>
          </div>

          <div className="bg-[#EFE8DF] h-full" id="faq">
            <div className="lg:px-10 px-2.5 lg:pt-[120px] sm:pt-[50px] pt-10" ref={faqSectionRef}>
              <h3 className="uppercase lg:text-[40px] sm:text-3xl text-2xl font-inter text-[#0F0F0F] font-medium lg:leading-[47.2px] sm:leading-[35.4px] leading-[28.32px]">
                FAQ
              </h3>
              <div className="lg:mt-8 mt-3">
                {faqs.map((faq, i) => (
                  <div
                    className={`border-t border-[#959595] py-[30px] ${
                      i + 1 == faqs.length && 'border-b border-[#959595]'
                    }`}
                    key={i}
                  >
                    {selectFaq.includes(faq.id) ? (
                      <div>
                        <div
                          className="flex items-start justify-between cursor-pointer"
                          onClick={() =>
                            setSelectFaq(() => selectFaq.filter(item => item !== faq.id))
                          }
                        >
                          <p className="lg:text-2xl text-sm font-inter font-medium text-[#0F0F0F] lg:leading-[24.2px] leading-[16.8px] pr-3">
                            {faq.question}
                          </p>
                          <IconSvg
                            icon="cross"
                            className="lg:w-auto lg:h-auto w-[10px] h-[10.4px] cursor-pointer"
                          />
                        </div>
                        <p className="lg:text-lg text-sm text-[#4A4A4A] mt-5 font-normal font-inter lg:leading-[21.8px] leading-[16.8px] pr-3 lg:w-[858px] w-full">
                          {faq.answer}
                        </p>
                      </div>
                    ) : (
                      <div
                        className="flex items-center justify-between cursor-pointer"
                        onClick={() => setSelectFaq(() => [faq.id])}
                      >
                        <p className="lg:text-2xl text-sm font-inter font-medium text-[#0F0F0F]  lg:leading-[24.2px] leading-[16.8px] pr-3">
                          {faq.question}
                        </p>
                        <IconSvg
                          icon="plus"
                          className="lg:w-auto lg:h-auto w-[10px] h-[10.4px] cursor-pointer"
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className=" sm:border-t border-collapse w-full border-[#4A4A4A] lg:mt-[120px] sm:mt-[50px] mt-[64px]"></div>
            <div className=" mt-16 pb-16 sm:px-8 px-2.5">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
