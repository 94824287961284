import { useMemo } from 'react';
import Select from 'react-select';

import styles from './SelectComponent.module.scss';
import { IconSvg } from '../../ui/IconSvg';

type Option = { label: string; value: string };

interface Props {
  id: string;
  name: string;
  options: Array<Option>;
  onChange: (input: { name: string; value: string }) => void;

  value?: Option | Array<Option>;
  label?: string;
  defaultValue?: Option;
  wrapperStyles?: string;
  isError?: boolean;
  isSearchable?: boolean;
  errorMessage?: string;
  placeholder?: string;
  disabled?: boolean;

  onBlur?: (input: { name: string; value: string }) => void;
  isMulti?: boolean;
}

export function SelectComponent({
  id,
  name,
  options,
  value,
  onChange,
  label,
  defaultValue,
  wrapperStyles,
  isError,
  isSearchable = true,
  errorMessage,
  placeholder,
  isMulti,
  disabled = false,
}: Props) {
  const onChangeHandler = (e: any) => {
    isMulti ? onChange(e) : onChange({ name, value: e.value });
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onInputChangeHandler = (e: any) => {};

  const customStyles = useMemo(
    () => ({
      control: (provided: any) => ({
        ...provided,
        'borderRadius': '5px', // Set border-radius
        'border': '1.5px solid #4A4A4A', // Set border color
        'boxShadow': 'none',
        'zIndex': 9999,
        'cursor': 'pointer',
        '&:hover': {
          border: '1.5px solid #4A4A4A', // Change border color on hover
        },
        'height': '40px',
        backgroundColor: '#EFE8DF',
      }),
      indicatorSeparator: (provided: any) => ({
        ...provided,
        display: 'none',
      }),
      dropdownIndicator: (provided: any) => ({
        ...provided,
        color: '#4A4A4A', // Set arrow color
        '&:hover': {
          color: '#4A4A4A', // Change text color on hover
        },
      }),
      menu: (provided: any) => ({
        ...provided,
        zIndex: 9999,
        backgroundColor: '#EFE8DF',
      }),
      option: (provided: any, state: any) => ({
        ...provided,
        'backgroundColor': state.isSelected ? '#EFE8DF' : state.isFocused ? '#EFE8DF' : '#EFE8DF',
        'color': state.isSelected ? '#C56B48' : '#4A4A4A', // Change text color
        'zIndex': 2,
        'cursor': 'pointer',
        '&:hover': {
          backgroundColor: '#EFE8DF',
          color: '#C56B48', // Change text color on hover
        },
      }),
      placeholder: (provided: any) => ({
        ...provided,
        color: '#4A4A4A', // Set text color for placeholder
      }),
      singleValue: (provided: any) => ({
        ...provided,
        color: '#4A4A4A', // Set text color for selected value
      }),
    }),
    [isError],
  );
  

  return (
    <div className={`${wrapperStyles} z-[1]`}>
      <label className={styles['label']} htmlFor={name}>
        {label}
      </label>

      <Select
        classNamePrefix="select-drop-arrow"
        id={id}
        name={name}
        value={value}
        options={options as any}
        onChange={onChangeHandler}
        placeholder={placeholder}
        isSearchable={isSearchable}
        onInputChange={onInputChangeHandler}
        styles={customStyles}
        defaultValue={defaultValue}
        isMulti={isMulti}
        isDisabled={disabled}
      />

      {/* {isError && errorMessage && <p className={styles['error-text']}>{errorMessage}</p>} */}
      {isError && (
        <div className="flex items-center gap-4 mt-2.5">
          {' '}
          <IconSvg icon="attentionCircle" />
          <p className={styles['error-text']}>{errorMessage}</p>
        </div>
      )}
    </div>
  );
}