import { Button, IconSvg } from 'src/components/common';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useContext, useEffect, useState } from 'react';
import { useHttp, useNotification } from 'src/hooks';
import { AuthContext } from 'src/contexts';
import { LoadingSpinner } from 'src/components/common/ui/loading-spinner/LoadingSpinner';
import { Checkbox, InputText, SelectComponent } from 'src/components/common/form';
import { useNavigate } from 'react-router-dom';
import { Currency } from 'src/components/manage-plans';

interface Coupon {
  name: string;
  id: string;
  duration: string;
  biannual: string;
  yearly: string;
  discountType: string;
  percentageOff?: string;
  amountOff?: string;
  redemptionLimit: string;
  timeRedemed?: string;
  // limitTotalTime?: boolean;
}
const convertDateTimeToUnixTimestamp = (dateString: string, timeString: string): number => {
  const date = new Date(`${dateString}T${timeString}:00`);
  return Math.floor(date.getTime() / 1000);
};
const validationSchema = Yup.object()
  .shape({
    name: Yup.string().required('Name is required'),
    duration: Yup.string().required('Duration is required'),
    biannual: Yup.string().optional(),
    yearly: Yup.string().optional(),
    discountType: Yup.string()
      .oneOf(['percentageOff', 'amountOff'], 'Please select a discount type')
      .required('Discount type is required'),

    percentageOff: Yup.string().when('discountType', {
      is: 'percentageOff',
      then: Yup.string()
        .required('Percentage off is required')
        .matches(/^\d+$/, 'Percentage off must be a number')
        .test(
          'max-100',
          'Percentage off cannot be more than 100',
          value => !value || Number(value) <= 100,
        ),
    }),
    amountOff: Yup.string().when('discountType', {
      is: 'amountOff',
      then: Yup.string()
        .required('Amount off is required')
        .matches(/^\d+$/, 'Amount off must be a number')
        .test(
          'max-100',
          'Amount off cannot be more than 100',
          value => !value || Number(value) <= 100,
        ),
    }),
    id: Yup.string().optional().matches(/^\S+$/, 'ID cannot contain spaces'),
    // redemptionLimit: Yup.string()
    //   .oneOf(['timeRedemed'], 'Please select a redemption limit')
    //   .notRequired(),
    // timeRedemed: Yup.string().when('redemptionLimit', {
    //   is: 'timeRedemed',
    //   then: Yup.string()
    //     .required('Time redemption is required')
    //     .matches(/^\d+$/, 'Time redemption must be a number'),
    //     otherwise: Yup.string().optional(),
    // }),
    redemptionLimit: Yup.string().optional(),
    timeRedemed: Yup.string().optional().matches(/^\d+$/, 'Time redemption must be a number'),
    expiryDate: Yup.lazy(expiryDate =>
      expiryDate || !expiryDate
        ? Yup.string().optional()
        : Yup.string().required('Expiry date is required'),
    ),
    expiryTime: Yup.lazy(expiryTime =>
      expiryTime || !expiryTime
        ? Yup.string().optional()
        : Yup.string().required('Expiry time is required'),
    ),

    addMultipleCurrencies: Yup.boolean(),
    currencyAmounts: Yup.array().when('addMultipleCurrencies', {
      is: true,
      then: Yup.array().of(
        Yup.object().shape({
          currencyId: Yup.string().required('Currency ID is required'),
          amountOff: Yup.number().required('Amount Off is required'),
        }),
      ),
      otherwise: Yup.array().of(Yup.object().shape({})), // optional if unchecked
    }),
  })
  .test(
    'expiryDate-time',
    'If expiry date is provided, expiry time must be provided and vice versa',
    function (values) {
      const { expiryDate, expiryTime } = values;
      if (expiryDate && !expiryTime) {
        return this.createError({
          path: 'expiryTime',
          message: 'Expiry time is required if expiry date is provided',
        });
      }
      if (!expiryDate && expiryTime) {
        return this.createError({
          path: 'expiryDate',
          message: 'Expiry date is required if expiry time is provided',
        });
      }
      return true;
    },
  );

export function CreateCoupon() {
  const [submitButton, setSubmitButton] = useState(false);
  const [plans, setPlans] = useState<any>([]);
  const [currencyOptions, setCurrencyOptions] = useState<{ label: string; value: string }[]>([]);
  const [defaultCurrency, setDefaultCurrency] = useState<{ label: string; value: string }>();
  const [currencies, setCurrencies] = useState<Currency[]>([]);

  const { sendRequest: apiRequestHandler, error, isLoading } = useHttp();
  const { accessToken, profile } = useContext(AuthContext);
  const notify = useNotification();
  const navigate = useNavigate();

  const formInputs = [
    {
      name: 'name',
      label: 'Name',
      placeholder: '',
      disabled: false,
      textArea: false,
      multiSelect: false,
    },
    {
      name: 'id',
      label: 'ID (Optional)',
      placeholder: '',
      disabled: false,
      textArea: false,
      multiSelect: false,
    },
  ];

  useEffect(() => {
    if (error) {
      notify(error, 'error');
    }
  }, [error]);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      id: '',
      duration: '',
      biannual: '',
      yearly: '',
      discountType: '',
      percentageOff: '',
      amountOff: '',
      redemptionLimit: '',
      timeRedemed: '',
      expiryTime: '',
      expiryDate: '',
      addMultipleCurrencies: false,
      currencyAmounts: [{ currencyId: '', amountOff: '' }],
    },
    validationSchema,
    onSubmit: values => {
      // console.log(convertDateTimeToUnixTimestamp(values.expiryDate, values.expiryTime), 'sub');
      const planIds = [];
      if (values.biannual) {
        planIds.push(values.biannual);
      }
      if (values.yearly) {
        planIds.push(values.yearly);
      }
      const payload = {
        couponId: values.id,
        planIds: planIds,
        ...(values.amountOff && { amountOff: +values.amountOff }),
        ...(values.percentageOff && { percentOff: +values.percentageOff }),
        ...(values.timeRedemed && { maxRedemptions: +values.timeRedemed }),
        name: values.name,
        duration: values.duration,
        ...(values.expiryTime &&
          values.expiryDate && {
            expiryTime: convertDateTimeToUnixTimestamp(values.expiryDate, values.expiryTime),
          }),
        ...(values.currencyAmounts?.length > 0 && {
          currencyAmounts: values.currencyAmounts
            .filter(currency => currency.currencyId && parseInt(currency.amountOff) > 0) // Filter out invalid entries
            .map(currency => ({
              currencyId: currency.currencyId,
              amountOff: +currency.amountOff,
            })),
        }),
      };

      apiRequestHandler(
        {
          url: 'coupon',
          method: 'POST',
          accessToken,
          payload,
        },
        (data: any) => {
          // setPlans(data);
          navigate('/coupons');

          notify('Coupon created successfully', 'success');
        },
      );
    },
  });
  const {
    values,
    errors,
    setFieldValue,
    handleSubmit,
    setValues,
    touched,
    resetForm,
    setFieldError,
  } = formik;

  const onChangeHandler = ({ name, value }: { name: string; value: string }) => {
    setFieldValue(name, value);
  };
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;

    if (name === 'percentageOff') {
      setFieldValue('discountType', 'percentageOff');

      setFieldValue('percentageOff', checked ? '' : '');
      setFieldValue('amountOff', '');
    } else if (name === 'amountOff') {
      setFieldValue('discountType', 'amountOff');

      setFieldValue('amountOff', checked ? '' : '');
      setFieldValue('percentageOff', '');
    } else {
      setFieldValue(name, checked);
    }
  };

  const handlePlansCheckboxChange = (name: string, value: string) => {
    const currentValue = values[name as keyof Coupon];
    setFieldValue(name, currentValue === value ? '' : value);
  };
  const durationOption = [
    {
      label: 'Forever',
      value: 'forever',
    },
    {
      label: 'Once',
      value: 'once',
    },
  ];
  const handleRedemptionLimitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    if (name === 'timeRedemed') {
      if (checked) {
        setFieldValue('redemptionLimit', name);
        setFieldValue(name, '');
        setFieldError('redemptionLimit', '');
        setFieldError('timeRedemed', '');
      } else {
        setFieldValue('redemptionLimit', '');
        setFieldValue(name, '');
        setFieldError('redemptionLimit', '');
        setFieldError('timeRedemed', '');
      }
    } else {
      setFieldValue(name, checked);
    }
  };

  const getPlansHandler = () => {
    apiRequestHandler(
      {
        url: 'payment/prices',
        method: 'GET',
      },
      (data: any) => {
        setPlans(data);
      },
    );
  };

  useEffect(() => {
    getPlansHandler();
  }, []);

  const plansOptions = [
    {
      label: 'BIANNUAL',
      name: 'biannual',
      value: plans[0]?.id,
      amount: plans[0]?.amount ? parseInt(plans[0]?.amount, 10) : 0,
    },
    {
      label: 'YEARLY',
      name: 'yearly',
      value: plans[1]?.id,
      amount: plans[1]?.amount ? parseInt(plans[1]?.amount, 10) : 0,
    },
  ];

  const handleCurrencyAmountChange = (input: { name: string; value: string }, index: number) => {
    const updatedCurrencyAmounts = [...values.currencyAmounts];
    updatedCurrencyAmounts[index].amountOff = input.value;

    formik.setFieldValue('currencyAmounts', updatedCurrencyAmounts);
  };

  const addCurrencyAmount = () => {
    formik.setFieldValue('currencyAmounts', [
      ...values.currencyAmounts,
      { amountOff: '', currency: '' },
    ]);
  };

  const getCurrencies = () => {
    apiRequestHandler(
      {
        url: 'currency',
        method: 'GET',
      },
      (data: any) => {
        setCurrencies(data);

        const options = data
          ?.filter((currency: Currency) => currency.code !== 'USD') // Exclude USD
          .map((currency: Currency) => ({
            label: `${currency.code}`,
            value: currency.id,
          }));

        setCurrencyOptions(options);

        if (options.length > 0) {
          setDefaultCurrency(options[0]);
        }
      },
    );
  };

  const handleCurrencyChange = (option: { name: string; value: string }, index: number) => {
    const updatedCurrencyAmounts = [...formik.values.currencyAmounts];
    updatedCurrencyAmounts[index].currencyId = option.value;
    formik.setFieldValue('currencyAmounts', updatedCurrencyAmounts);
  };
  const removeCurrencyAmount = (index: number) => {
    const updatedCurrencyAmounts = [...values.currencyAmounts];
    updatedCurrencyAmounts.splice(index, 1);
    formik.setFieldValue('currencyAmounts', updatedCurrencyAmounts);
  };

  useEffect(() => {
    getCurrencies();
  }, []);
  return (
    <>
      <div className="">
        <h2 className="text-[#0F0F0F] font-medium font-inter lg:text-3xl sm:text-2xl text-xl lg:leading-[35.4px] sm:leading-[28.32px] leading-[23.6px] uppercase">
          Create a coupon
        </h2>
        <form
          onSubmit={e => {
            e.preventDefault();
            setSubmitButton(true);
            handleSubmit();
          }}
        >
          <div className="sm:border  sm:border-[#959595] border-collapse rounded-[5px] lg:p-7 sm:p-6 p-0 lg:mt-5 sm:mt-3 mt-5">
            <div className="sm:w-[600px] w-full">
              {formInputs.map((sub, i) => (
                <div className={` w-full ${i !== 0 ? 'mt-5' : ''}`} key={i}>
                  <InputText
                    id={sub.name}
                    name={sub.name}
                    label={sub.label}
                    placeholder={sub.placeholder}
                    value={values[sub.name as keyof Coupon]}
                    onChange={onChangeHandler}
                    isDisabled={sub.disabled}
                    isError={
                      touched[sub.name as keyof Coupon] && !!errors[sub.name as keyof Coupon]
                    }
                    errorMessage={errors[sub.name as keyof Coupon]}
                  />
                  {sub.name === 'id' && (
                    <p className="text-sm text-gray-500 mt-1">
                      This will identify this coupon in the API. We recommend leaving this blank so
                      we can generate an ID for you.
                    </p>
                  )}
                </div>
              ))}

              <div>
                <p className="font-inter text-sm leading-6 font-medium text-[#4A4A4A] mb-2 mt-5">
                  Type
                </p>
                <div className="">
                  <Checkbox
                    id="percentageOff"
                    checked={values.discountType === 'percentageOff'}
                    onChange={handleCheckboxChange}
                    name="percentageOff"
                    label={'Percentage discount'}
                  />
                </div>
                <div className="pt-[18px] flex items-center gap-2.5">
                  <Checkbox
                    id="amountOff"
                    checked={values.discountType === 'amountOff'}
                    onChange={handleCheckboxChange}
                    name="amountOff"
                    label={'Fixed amount discount'}
                  />
                </div>

                {touched['discountType'] && !!errors['discountType'] && (
                  <div className="flex items-center gap-4 mt-2.5">
                    <IconSvg icon="attentionCircle" />
                    <p className="font-semibold text-sm font-inter leading-[17.81px] text-[#9B2626] mt-1">
                      {errors['discountType']}
                    </p>
                  </div>
                )}
              </div>
              {values.discountType === 'amountOff' && (
                <div className="mt-5">
                  <InputText
                    id={'amountOff'}
                    name={'amountOff'}
                    label={'Amount off (USD)'}
                    value={values.amountOff}
                    placeholder={''}
                    onChange={onChangeHandler}
                    isError={touched.amountOff && !!errors.amountOff}
                    errorMessage={errors.amountOff}
                  />
                  <p className="text-sm text-gray-500 mt-1">
                    To enable the use of multiple currencies in your app, you must set a fixed
                    amount discount for each currency.
                  </p>
                </div>
              )}
              {values.discountType === 'amountOff' && (
                <div>
                  <div className="pt-[18px] flex items-center gap-2.5">
                    <Checkbox
                      id="addMultipleCurrencies"
                      name="addMultipleCurrencies"
                      checked={values.addMultipleCurrencies}
                      onChange={handleCheckboxChange}
                      label={'Add multiple currencies'}
                    />
                  </div>
                </div>
              )}
              {values.discountType === 'amountOff' && values?.addMultipleCurrencies && (
                <div>
                  {values?.currencyAmounts.map((currency, index) => (
                    <div key={index} className="flex items-start w-full gap-4  mt-4  relative">
                      {' '}
                      {/* Ensure relative positioning */}
                      <InputText
                        id={`amountOff-${index}`}
                        name={`currencyAmounts[${index}].amountOff`}
                        value={currency?.amountOff || ''}
                        placeholder={'Enter amount'}
                        onChange={e => handleCurrencyAmountChange(e, index)}
                        isError={
                          touched.currencyAmounts?.[index]?.amountOff &&
                          !!(errors.currencyAmounts?.[index] as { amountOff?: string })?.amountOff
                        }
                        errorMessage={
                          (errors.currencyAmounts?.[index] as { amountOff?: string })?.amountOff
                        }
                      />
                      <SelectComponent
                        id={`currency-select-${index}`}
                        name={`currencyAmounts[${index}].currencyId`}
                        options={currencyOptions.filter(
                          option =>
                            !values.currencyAmounts.some(curr => curr.currencyId === option.value),
                        )}
                        onChange={option => handleCurrencyChange(option, index)}
                        placeholder="Select a currency"
                        value={currencyOptions.find(opt => opt.value === currency?.currencyId)}
                        wrapperStyles="flex items-center z-10" // Ensure dropdown has higher z-index
                      />
                      <button
                        type="button"
                        className="text-red-500"
                        onClick={() => removeCurrencyAmount(index)}
                      >
                        &times; {/* Remove button */}
                      </button>
                    </div>
                  ))}

                  {/* Button to add another currency */}
                  {currencyOptions.some(
                    option =>
                      !values.currencyAmounts.some(curr => curr.currencyId === option.value),
                  ) && (
                    <button type="button" className="mt-4" onClick={addCurrencyAmount}>
                      Add Another Currency
                    </button>
                  )}
                </div>
              )}

              {values.discountType === 'percentageOff' && (
                <div className="mt-5">
                  <InputText
                    id={'percentageOff'}
                    name={'percentageOff'}
                    value={values.percentageOff}
                    label={'Percentage off'}
                    placeholder={''}
                    icon="percentage"
                    onChange={onChangeHandler}
                    isError={touched.percentageOff && !!errors.percentageOff}
                    errorMessage={errors.percentageOff}
                  />
                </div>
              )}
              <div>
                <p className="font-inter text-sm leading-6 font-medium text-[#4A4A4A] mt-5">
                  Plans
                </p>
                {plansOptions.map((plan, index: number) => (
                  <div className="pt-[18px]" key={index}>
                    <Checkbox
                      id={plan.name}
                      checked={!!values[plan.name as keyof Coupon]}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handlePlansCheckboxChange(e.target.name, plan.value)
                      }
                      name={plan.name}
                      label={plan.label}
                    />
                  </div>
                ))}
                {submitButton && !values.biannual && !values.yearly && (
                  <div className="flex items-center gap-4 mt-2.5">
                    <IconSvg icon="attentionCircle" />
                    <p className="font-semibold text-sm font-inter leading-[17.81px] text-[#9B2626] mt-1">
                      Please select at least one plan
                    </p>
                  </div>
                )}
              </div>
              <div className="mt-5">
                <SelectComponent
                  id={'duration'}
                  name={'duration'}
                  label={'Duration'}
                  placeholder={''}
                  options={durationOption || []}
                  value={durationOption?.find(c => c.value === values['duration'])}
                  onChange={onChangeHandler}
                  isError={touched['duration'] && !!errors['duration']}
                  errorMessage={errors['duration']}
                />
              </div>
              <div className="">
                <p className="font-inter text-sm leading-6 font-medium text-[#4A4A4A] mb-2 mt-5">
                  Redemption limits
                </p>
                <Checkbox
                  id="timeRedemed"
                  checked={values.redemptionLimit === 'timeRedemed'}
                  onChange={handleRedemptionLimitChange}
                  name="timeRedemed"
                  label={'Limit the total number of times this coupon can be redeemed'}
                />
                {touched['redemptionLimit'] && !!errors['redemptionLimit'] && (
                  <div className="flex items-center gap-4 mt-2.5">
                    <IconSvg icon="attentionCircle" />
                    <p className="font-semibold text-sm font-inter leading-[17.81px] text-[#9B2626] mt-1">
                      {errors['redemptionLimit']}
                    </p>
                  </div>
                )}
              </div>
              {values.redemptionLimit === 'timeRedemed' && (
                <div className="mt-5">
                  <InputText
                    id={'timeRedemed'}
                    name={'timeRedemed'}
                    label={''}
                    placeholder={''}
                    value={values.timeRedemed}
                    tag={
                      <p className="font-inter font-light text-sm leading-6 text-[#4A4A4A] absolute inset-y-0 right-2.5 my-auto flex items-center">
                        time
                      </p>
                    }
                    onChange={onChangeHandler}
                    isError={touched.timeRedemed && !!errors.timeRedemed}
                    errorMessage={errors.timeRedemed}
                  />
                </div>
              )}
              <div>
                <p className="font-inter text-sm leading-6 font-medium text-[#4A4A4A] mb-2 mt-5">
                  Add an expiration date
                </p>
                <div className="flex items-start gap-4 w-full">
                  <div className="w-full">
                    <InputText
                      id={'expiryDate'}
                      name={'expiryDate'}
                      label={''}
                      type="date"
                      value={values.expiryDate}
                      placeholder={''}
                      onChange={onChangeHandler}
                      isError={touched.expiryDate && !!errors.expiryDate}
                      errorMessage={errors.expiryDate}
                    />
                  </div>
                  <div className="w-full">
                    <InputText
                      id={'expiryTime'}
                      name={'expiryTime'}
                      label={''}
                      type="time"
                      value={values.expiryTime}
                      placeholder={''}
                      onChange={onChangeHandler}
                      isError={touched.expiryTime && !!errors.expiryTime}
                      errorMessage={errors.expiryTime}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex items-start gap-4 mt-5">
              <Button
                size="small"
                color="yellow"
                type="submit"
                label={
                  <p className="uppercase text-[#0F0F0F] font-medium font-inter sm:text-lg text-[12px] sm:leading-[21.6px] leading-[14.4px]">
                    Submit
                  </p>
                }
                className="px-10 lg:w-auto lg:h-[40px] w-full"
              />
            </div>
          </div>
        </form>
      </div>
      {isLoading && <LoadingSpinner />}
    </>
  );
}
