import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Checkbox, InputText } from '../common/form';
import { Button, IconSvg } from '../common';
import { useNavigate } from 'react-router-dom';

import { GoogleLogin } from '@react-oauth/google';
import GoogleLoginButton from '../common/ui/button/GoogleLoginButton';
const passwordRules =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().required('Email is required'),
  password: Yup.string()
    .required('Password is required')
    .matches(
      passwordRules,
      'Password must be at least 8 characters long and contain at least 3 of the following: lowercase letters, uppercase letters, numbers, and special characters',
    ),
  termsAndPolicy: Yup.boolean().oneOf([true], 'Please must accept the terms and policy'),
  ageConfirmation: Yup.boolean().oneOf([true], 'Please must confirm your age'),
});

interface PersonalDetailsForm {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  termsAndPolicy: boolean;
  ageConfirmation: boolean;
}
interface Props {
  onSubmitHandler: (payload: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
  }) => void;
  planId: string;
  currencyId: string;
}

export function PersonalDetails({ onSubmitHandler, planId, currencyId }: Props) {
  const navigate = useNavigate();
  const googleClientId = '366279549834-atm42jjieqkuoov5c3g2pvvil310205d.apps.googleusercontent.com';

  if (!googleClientId) {
    throw new Error('Google Client Id is not defined');
  }
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      termsAndPolicy: false,
      ageConfirmation: false,
    },
    validationSchema,
    onSubmit: async values => {
      console.log({ values });
      const { termsAndPolicy, ageConfirmation, ...rest } = values;
      onSubmitHandler(rest);
    },
  });
  const { values, errors, setFieldValue, handleSubmit, setValues, touched } = formik;

  const onChangeHandler = ({ name, value }: { name: string; value: string }) => {
    setFieldValue(name, value);
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFieldValue(name, checked);
  };

  return (
    <>
      <h3 className="text-xl font-inter font-bold text-[#0F0F0F] leading-6 ">
        Step 2: Personal Details
      </h3>
      <h6 className="text-sm font-light mt-1 text-[#4A4A4A] font-inter">
        Please share with us some details to proceed.
      </h6>

      <form onSubmit={handleSubmit}>
        <div className="mt-[30px]">
          <div className="sm:flex gap-5 w-full">
            <div className="w-full">
              <InputText
                id={'city'}
                name={'firstName'}
                label={'First Name'}
                placeholder={'Enter first name'}
                value={values['firstName']}
                onChange={onChangeHandler}
                //   isDisabled={item.disabled}
                // wrapperStyles={styles['field-wrapper']}
                isError={
                  touched['firstName' as keyof PersonalDetailsForm] &&
                  !!errors['firstName' as keyof PersonalDetailsForm]
                }
                errorMessage={errors['firstName' as keyof PersonalDetailsForm]}
              />
            </div>
            <div className="w-full sm:mt-0 mt-5">
              <InputText
                id={'city'}
                name={'lastName'}
                label={'Last Name'}
                placeholder={'Enter last name'}
                value={values['lastName']}
                onChange={onChangeHandler}
                //   isDisabled={item.disabled}
                // wrapperStyles={styles['field-wrapper']}
                isError={
                  touched['lastName' as keyof PersonalDetailsForm] &&
                  !!errors['lastName' as keyof PersonalDetailsForm]
                }
                errorMessage={errors['lastName' as keyof PersonalDetailsForm]}
              />
            </div>
          </div>
          <div className="my-4">
            <InputText
              id={'email'}
              name={'email'}
              label={'Email'}
              placeholder={'Enter email'}
              value={values['email']}
              onChange={onChangeHandler}
              //   isDisabled={item.disabled}
              // wrapperStyles={styles['field-wrapper']}
              isError={
                touched['email' as keyof PersonalDetailsForm] &&
                !!errors['email' as keyof PersonalDetailsForm]
              }
              errorMessage={errors['email' as keyof PersonalDetailsForm]}
            />
          </div>
          <div className="">
            <InputText
              id={'password'}
              name={'password'}
              label={'Password'}
              placeholder={'Enter password'}
              type="password"
              value={values['password']}
              icon="eye"
              onChange={onChangeHandler}
              //   isDisabled={item.disabled}
              // wrapperStyles={styles['field-wrapper']}
              isError={
                touched['password' as keyof PersonalDetailsForm] &&
                !!errors['password' as keyof PersonalDetailsForm]
              }
              errorMessage={errors['password' as keyof PersonalDetailsForm]}
            />
          </div>
          <div>
            <div className="pt-6 flex items-center gap-2.5">
              <Checkbox
                id="agreement"
                checked={values.termsAndPolicy}
                //   onChange={() => handleCheckboxChange(transaction.id)}
                onChange={handleCheckboxChange}
                name="termsAndPolicy"
                isError={touched['termsAndPolicy'] && !!errors['termsAndPolicy']}
                errorMessage={errors['termsAndPolicy']}
                label={
                  <p className="font-inter text-[13px] font-light leading[15.6] text-[#4A4A4A]">
                    By signing-up you agree to our{' '}
                    <span
                      className="font-medium border-b border-[#4A4A4A] inline-block cursor-pointer"
                      onClick={e => {
                        e.stopPropagation();
                        navigate('/terms-use');
                      }}
                    >
                      Terms of use
                    </span>
                    ,{' '}
                    <span
                      className="font-medium border-b border-[#4A4A4A] inline-block cursor-pointer"
                      onClick={e => {
                        e.stopPropagation();
                        navigate('/terms-sale');
                      }}
                    >
                      Terms of Sale
                    </span>{' '}
                    and{' '}
                    <span
                      className="font-medium border-b border-[#4A4A4A] inline-block cursor-pointer"
                      onClick={e => {
                        e.stopPropagation();
                        navigate('/privacy-policy');
                      }}
                    >
                      Privacy Policy.
                    </span>
                  </p>
                }
              />
            </div>
            {touched['termsAndPolicy'] && !!errors['termsAndPolicy'] && (
              <div className="flex items-center gap-4 mt-2.5">
                <IconSvg icon="attentionCircle" />
                <p className="font-semibold text-sm font-inter leading-[17.81px] text-[#9B2626] mt-1">
                  {errors['termsAndPolicy']}
                </p>
              </div>
            )}
          </div>
          <div>
            <div className="pt-[18px] flex items-center gap-2.5">
              <Checkbox
                id="age-confirmation"
                checked={values.ageConfirmation}
                onChange={handleCheckboxChange}
                name="ageConfirmation"
                errorMessage={errors['ageConfirmation']}
                label={'I confirm I am above 18 y.o.'}
              />
              {/* <p className="font-inter text-[13px] font-light leading[15.6] text-[#4A4A4A]">
                I confirm I am above 18 y.o.
              </p> */}
            </div>
            {touched['ageConfirmation'] && !!errors['ageConfirmation'] && (
              <div className="flex items-center gap-4 mt-2.5">
                <IconSvg icon="attentionCircle" />
                <p className="font-semibold text-sm font-inter leading-[17.81px] text-[#9B2626] mt-1">
                  {errors['ageConfirmation']}
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="sm:pb-0 pb-[45px]">
          <Button
            size="small"
            color="primary"
            label={
              <p className="uppercase text-[#EFE8DF] font-medium font-inter text-base leading-[21.6px]">
                Continue
              </p>
            }
            type="submit"
            className="w-full mt-[21px]"
            onClick={() => {
              // setCurrentComponent(prev => prev + 1);
              // subscriptionModalHandler(plan.id);
              // setSelectedPlan(plan);
            }}
          />
        </div>
        <div className="flex items-center w-full sm:my-5 my-[15px]">
          <div className="w-full border-t border-[#959595]"></div>
          <p className="text-[#959595] font-medium font-inter text-lg px-4">or</p>
          <div className="w-full border-t border-[#959595]"></div>
        </div>
        <div className="lg:pb-0 sm:pb-[200px] pb-0">
          <div className="border rounded-[5px] border-[#4A4A4A] py-2 flex justify-center items-center gap-4 bg-[#4A4A4A]">
            <GoogleLoginButton
              label={'SIGN UP WITH GOOGLE'}
              type={'signup'}
              planId={planId}
              currencyId={currencyId}
            />
          </div>
        </div>
      </form>
    </>
  );
}
