import {useContext, useMemo} from 'react';
import Select, { components } from 'react-select';

import styles from './SelectComponent.module.scss';
import {AuthContext} from "../../../contexts";

type Option = { label: string; value: string; amount: number };

interface Props {
  id: string;
  name: string;
  options: Array<Option>;
  onChange: (input: { name: string; value: string; amount: number }) => void;

  value?: Option | Array<Option>;
  label?: string;
  defaultValue?: Option;
  wrapperStyles?: string;
  isError?: boolean;
  isSearchable?: boolean;
  errorMessage?: string;
  placeholder?: string;
  disabled?: boolean;

  onBlur?: (input: { name: string; value: string }) => void;
  isMulti?: boolean;
  indicatorValue?: string;
  couponDiscount?: string;
  discountType?: string;
}

const DropdownIndicator = ({ indicatorValue, couponDiscount,discountType, ...props }: any) => {
  const { profile } = useContext(AuthContext);

  return (
    <components.DropdownIndicator {...props}>
      <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
        <span className="font-bold sm:text-xl text-base font-inter text-[#0F0F0F] flex items-center">
          {indicatorValue}
          {couponDiscount && couponDiscount !== 'undefined' ? (
            <span className="bg-[#4A4A4A] px-[6.9px] py-[4.5px] text-[#EFE8DF] rounded-[6.9px] text-[11.03px] leading-[13.24px] ml-2.5">
              { discountType === 'percent' ? `(SAVED ${couponDiscount}%)` : `(SAVED ${couponDiscount} ${profile?.account?.currency?.code})` }
            </span>
          ) : null}
        </span>
        <components.DropdownIndicator {...props} />
      </div>
    </components.DropdownIndicator>
  );
};

const CustomOption = (props: any) => {
  return (
    <components.Option {...props}>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <span>{props.data.label}</span>
        <span>
          {props.data.amount} {props.data.currency}
        </span>
      </div>
    </components.Option>
  );
};

export function PlansSelectComponent({
  id,
  name,
  options,
  value,
  onChange,
  label,
  defaultValue,
  wrapperStyles,
  isError,
  isSearchable = true,
  errorMessage,
  placeholder,
  isMulti,
  disabled = false,
  indicatorValue,
  couponDiscount,
                                       discountType,
}: Props) {
  const onChangeHandler = (e: any) => {
    isMulti ? onChange(e) : onChange({ name: e.label, value: e.value, amount: e.amount });
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onInputChangeHandler = (e: any) => {};

  const customStyles = useMemo(
    () => ({
      control: (provided: any) => ({
        ...provided,
        'border': `1px solid ${isError ? '#ea174a' : '#959595'}`,
        'boxShadow': 'none',
        // 'zIndex': 2,
        '&:hover': {
          border: `1px solid ${isError ? '#ea174a' : '#959595'}`,
        },
        'maxWidth': '512',
        'padding': '4px 0px',
        'fontSize': '20px',
        'backgroundColor': '#EFE8DF', // Set background color for the input field
        'zIndex': 9999,
      }),
      indicatorsContainer: (provided: any) => ({
        ...provided,
        height: '100%',
      }),
      indicatorSeparator: (provided: any) => ({
        ...provided,
        display: 'none',
        height: '100%',
      }),
      menu: (provided: any) => ({
        ...provided,
        zIndex: 9999,
        backgroundColor: '#EFE8DF',
      }),
      option: (provided: any, state: any) => ({
        ...provided,
        'backgroundColor': state.isSelected ? '#4A4A4A' : state.isFocused ? '#F0F1F1' : '#EFE8DF',
        'color': state.isSelected ? '#EFE8DF' : 'inherit',
        'zIndex': 2,
        '&:hover': {
          backgroundColor: state.isSelected ? '#4A4A4A' : '#EFE8DF',
        },
      }),
      placeholder: (provided: any) => ({
        ...provided,
        color: '#0000007A',
      }),
    }),
    [isError],
  );

  return (
    <div className={wrapperStyles}>
      <label className={styles['label']} htmlFor={name}>
        {label}
      </label>

      <Select
        classNamePrefix="select-drop-arrow"
        id={id}
        name={name}
        value={value}
        options={options as any}
        onChange={onChangeHandler}
        placeholder={placeholder}
        isSearchable={isSearchable}
        onInputChange={onInputChangeHandler}
        styles={customStyles}
        defaultValue={defaultValue}
        isMulti={isMulti}
        isDisabled={disabled}
        components={{
          DropdownIndicator: props => (
            <DropdownIndicator
              {...props}
              indicatorValue={indicatorValue}
              couponDiscount={couponDiscount}
              discountType={discountType}
            />
          ),
          Option: CustomOption,
        }}
      />

      {isError && errorMessage && <p className={styles['error-text']}>{errorMessage}</p>}
    </div>
  );
}
